/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
export const onInitialClientRender = () => {
  document.getElementById("___gatsby").style.display = "block";

  // setTimeout(function () {
  //   document.getElementById("___loader").style.display = "none";
  // }, 1500);

  setTimeout(function () {
    document.getElementById("___loader").style.display = "none";
  }, 0);
};

// export const shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition,
// }) => {
//   return false;
// };
