// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-milestones-index-js": () => import("./../../../src/pages/milestones/index.js" /* webpackChunkName: "component---src-pages-milestones-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-projects-amolikgroup-js": () => import("./../../../src/pages/projects/amolikgroup.js" /* webpackChunkName: "component---src-pages-projects-amolikgroup-js" */),
  "component---src-pages-projects-arorahousebikaner-js": () => import("./../../../src/pages/projects/arorahousebikaner.js" /* webpackChunkName: "component---src-pages-projects-arorahousebikaner-js" */),
  "component---src-pages-projects-arravalihairtransplantclinic-js": () => import("./../../../src/pages/projects/arravalihairtransplantclinic.js" /* webpackChunkName: "component---src-pages-projects-arravalihairtransplantclinic-js" */),
  "component---src-pages-projects-atravillamashobra-js": () => import("./../../../src/pages/projects/atravillamashobra.js" /* webpackChunkName: "component---src-pages-projects-atravillamashobra-js" */),
  "component---src-pages-projects-balanahousejaipur-js": () => import("./../../../src/pages/projects/balanahousejaipur.js" /* webpackChunkName: "component---src-pages-projects-balanahousejaipur-js" */),
  "component---src-pages-projects-buildersfloorashokvihar-js": () => import("./../../../src/pages/projects/buildersfloorashokvihar.js" /* webpackChunkName: "component---src-pages-projects-buildersfloorashokvihar-js" */),
  "component---src-pages-projects-buildersfloorgujrawala-js": () => import("./../../../src/pages/projects/buildersfloorgujrawala.js" /* webpackChunkName: "component---src-pages-projects-buildersfloorgujrawala-js" */),
  "component---src-pages-projects-buildersfloormodeltown-js": () => import("./../../../src/pages/projects/buildersfloormodeltown.js" /* webpackChunkName: "component---src-pages-projects-buildersfloormodeltown-js" */),
  "component---src-pages-projects-buildersfloornorthex-js": () => import("./../../../src/pages/projects/buildersfloornorthex.js" /* webpackChunkName: "component---src-pages-projects-buildersfloornorthex-js" */),
  "component---src-pages-projects-cavokaviation-js": () => import("./../../../src/pages/projects/cavokaviation.js" /* webpackChunkName: "component---src-pages-projects-cavokaviation-js" */),
  "component---src-pages-projects-ceoofficepmtloni-js": () => import("./../../../src/pages/projects/ceoofficepmtloni.js" /* webpackChunkName: "component---src-pages-projects-ceoofficepmtloni-js" */),
  "component---src-pages-projects-commercial-js": () => import("./../../../src/pages/projects/commercial.js" /* webpackChunkName: "component---src-pages-projects-commercial-js" */),
  "component---src-pages-projects-dpsdwarka-js": () => import("./../../../src/pages/projects/dpsdwarka.js" /* webpackChunkName: "component---src-pages-projects-dpsdwarka-js" */),
  "component---src-pages-projects-dpsfaridabad-js": () => import("./../../../src/pages/projects/dpsfaridabad.js" /* webpackChunkName: "component---src-pages-projects-dpsfaridabad-js" */),
  "component---src-pages-projects-dpsgreaternoida-js": () => import("./../../../src/pages/projects/dpsgreaternoida.js" /* webpackChunkName: "component---src-pages-projects-dpsgreaternoida-js" */),
  "component---src-pages-projects-dpsnoida-js": () => import("./../../../src/pages/projects/dpsnoida.js" /* webpackChunkName: "component---src-pages-projects-dpsnoida-js" */),
  "component---src-pages-projects-dpspatna-js": () => import("./../../../src/pages/projects/dpspatna.js" /* webpackChunkName: "component---src-pages-projects-dpspatna-js" */),
  "component---src-pages-projects-dpsrkpuram-js": () => import("./../../../src/pages/projects/dpsrkpuram.js" /* webpackChunkName: "component---src-pages-projects-dpsrkpuram-js" */),
  "component---src-pages-projects-dpsrohini-js": () => import("./../../../src/pages/projects/dpsrohini.js" /* webpackChunkName: "component---src-pages-projects-dpsrohini-js" */),
  "component---src-pages-projects-dpsvasantkunj-js": () => import("./../../../src/pages/projects/dpsvasantkunj.js" /* webpackChunkName: "component---src-pages-projects-dpsvasantkunj-js" */),
  "component---src-pages-projects-emeofficersmessdelhicantt-js": () => import("./../../../src/pages/projects/emeofficersmessdelhicantt.js" /* webpackChunkName: "component---src-pages-projects-emeofficersmessdelhicantt-js" */),
  "component---src-pages-projects-fssai-js": () => import("./../../../src/pages/projects/fssai.js" /* webpackChunkName: "component---src-pages-projects-fssai-js" */),
  "component---src-pages-projects-iasacademy-js": () => import("./../../../src/pages/projects/iasacademy.js" /* webpackChunkName: "component---src-pages-projects-iasacademy-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-instituteofliverandbillarysciencesnewdelhi-js": () => import("./../../../src/pages/projects/instituteofliverandbillarysciencesnewdelhi.js" /* webpackChunkName: "component---src-pages-projects-instituteofliverandbillarysciencesnewdelhi-js" */),
  "component---src-pages-projects-institutions-js": () => import("./../../../src/pages/projects/institutions.js" /* webpackChunkName: "component---src-pages-projects-institutions-js" */),
  "component---src-pages-projects-interiors-js": () => import("./../../../src/pages/projects/interiors.js" /* webpackChunkName: "component---src-pages-projects-interiors-js" */),
  "component---src-pages-projects-investmentcorporationchennai-js": () => import("./../../../src/pages/projects/investmentcorporationchennai.js" /* webpackChunkName: "component---src-pages-projects-investmentcorporationchennai-js" */),
  "component---src-pages-projects-itcgoa-js": () => import("./../../../src/pages/projects/itcgoa.js" /* webpackChunkName: "component---src-pages-projects-itcgoa-js" */),
  "component---src-pages-projects-itcpahalgam-js": () => import("./../../../src/pages/projects/itcpahalgam.js" /* webpackChunkName: "component---src-pages-projects-itcpahalgam-js" */),
  "component---src-pages-projects-itcrajkot-js": () => import("./../../../src/pages/projects/itcrajkot.js" /* webpackChunkName: "component---src-pages-projects-itcrajkot-js" */),
  "component---src-pages-projects-itcsrinagar-js": () => import("./../../../src/pages/projects/itcsrinagar.js" /* webpackChunkName: "component---src-pages-projects-itcsrinagar-js" */),
  "component---src-pages-projects-kandymultimodaltransitterminal-js": () => import("./../../../src/pages/projects/kandymultimodaltransitterminal.js" /* webpackChunkName: "component---src-pages-projects-kandymultimodaltransitterminal-js" */),
  "component---src-pages-projects-krishnafarms-js": () => import("./../../../src/pages/projects/krishnafarms.js" /* webpackChunkName: "component---src-pages-projects-krishnafarms-js" */),
  "component---src-pages-projects-landscape-js": () => import("./../../../src/pages/projects/landscape.js" /* webpackChunkName: "component---src-pages-projects-landscape-js" */),
  "component---src-pages-projects-masterplanning-js": () => import("./../../../src/pages/projects/masterplanning.js" /* webpackChunkName: "component---src-pages-projects-masterplanning-js" */),
  "component---src-pages-projects-mohanfarms-js": () => import("./../../../src/pages/projects/mohanfarms.js" /* webpackChunkName: "component---src-pages-projects-mohanfarms-js" */),
  "component---src-pages-projects-mothersoncorporateparknoida-js": () => import("./../../../src/pages/projects/mothersoncorporateparknoida.js" /* webpackChunkName: "component---src-pages-projects-mothersoncorporateparknoida-js" */),
  "component---src-pages-projects-nailritualgurgaon-js": () => import("./../../../src/pages/projects/nailritualgurgaon.js" /* webpackChunkName: "component---src-pages-projects-nailritualgurgaon-js" */),
  "component---src-pages-projects-orchidinfrastructuredevelopers-js": () => import("./../../../src/pages/projects/orchidinfrastructuredevelopers.js" /* webpackChunkName: "component---src-pages-projects-orchidinfrastructuredevelopers-js" */),
  "component---src-pages-projects-pasrichafarmhouse-js": () => import("./../../../src/pages/projects/pasrichafarmhouse.js" /* webpackChunkName: "component---src-pages-projects-pasrichafarmhouse-js" */),
  "component---src-pages-projects-pravaramedicaltrustloni-js": () => import("./../../../src/pages/projects/pravaramedicaltrustloni.js" /* webpackChunkName: "component---src-pages-projects-pravaramedicaltrustloni-js" */),
  "component---src-pages-projects-pravaramedicaltrustlonimaharashtra-js": () => import("./../../../src/pages/projects/pravaramedicaltrustlonimaharashtra.js" /* webpackChunkName: "component---src-pages-projects-pravaramedicaltrustlonimaharashtra-js" */),
  "component---src-pages-projects-ratnadeepinstituteofmedicalsciences-js": () => import("./../../../src/pages/projects/ratnadeepinstituteofmedicalsciences.js" /* webpackChunkName: "component---src-pages-projects-ratnadeepinstituteofmedicalsciences-js" */),
  "component---src-pages-projects-residential-js": () => import("./../../../src/pages/projects/residential.js" /* webpackChunkName: "component---src-pages-projects-residential-js" */),
  "component---src-pages-projects-riteshguptaatsnoida-js": () => import("./../../../src/pages/projects/riteshguptaatsnoida.js" /* webpackChunkName: "component---src-pages-projects-riteshguptaatsnoida-js" */),
  "component---src-pages-projects-sgtuniversity-js": () => import("./../../../src/pages/projects/sgtuniversity.js" /* webpackChunkName: "component---src-pages-projects-sgtuniversity-js" */),
  "component---src-pages-projects-showflatbangalore-js": () => import("./../../../src/pages/projects/showflatbangalore.js" /* webpackChunkName: "component---src-pages-projects-showflatbangalore-js" */),
  "component---src-pages-projects-tdimarketingofficekundli-js": () => import("./../../../src/pages/projects/tdimarketingofficekundli.js" /* webpackChunkName: "component---src-pages-projects-tdimarketingofficekundli-js" */),
  "component---src-pages-projects-terracegardens-js": () => import("./../../../src/pages/projects/terracegardens.js" /* webpackChunkName: "component---src-pages-projects-terracegardens-js" */),
  "component---src-pages-projects-twinhousebikaner-js": () => import("./../../../src/pages/projects/twinhousebikaner.js" /* webpackChunkName: "component---src-pages-projects-twinhousebikaner-js" */),
  "component---src-pages-projects-varunagrawal-js": () => import("./../../../src/pages/projects/varunagrawal.js" /* webpackChunkName: "component---src-pages-projects-varunagrawal-js" */),
  "component---src-pages-projects-vikhepatilfoundation-js": () => import("./../../../src/pages/projects/vikhepatilfoundation.js" /* webpackChunkName: "component---src-pages-projects-vikhepatilfoundation-js" */),
  "component---src-pages-projects-vikhepatilfoundationboyshostel-js": () => import("./../../../src/pages/projects/vikhepatilfoundationboyshostel.js" /* webpackChunkName: "component---src-pages-projects-vikhepatilfoundationboyshostel-js" */),
  "component---src-pages-projects-vikhepatilfoundationgirlshostel-js": () => import("./../../../src/pages/projects/vikhepatilfoundationgirlshostel.js" /* webpackChunkName: "component---src-pages-projects-vikhepatilfoundationgirlshostel-js" */),
  "component---src-pages-projects-vpsssahmednagar-js": () => import("./../../../src/pages/projects/vpsssahmednagar.js" /* webpackChunkName: "component---src-pages-projects-vpsssahmednagar-js" */),
  "component---src-pages-projects-vpssspune-js": () => import("./../../../src/pages/projects/vpssspune.js" /* webpackChunkName: "component---src-pages-projects-vpssspune-js" */),
  "component---src-pages-team-index-js": () => import("./../../../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

